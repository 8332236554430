<template>
    <div>
        <div class="filters">
            <div class="row justify-content-between">
                <div class="col-md-6">
                    <div class="filters--keyword">
                        <input
                            type="text"
                            :placeholder="
                                $t('Search by Name, Position or any Keywords')
                            "
                            class="form-control"
                            @input="changeSearch"
                            v-model="search"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="filters--by">
                        <div class="row align-items-center justify-content-end">
                            <div class="col-sm-3">
                                <label class="control-label">{{
                                    $t('Filter by')
                                }}</label>
                            </div>
                            <div class="col-sm-7">
                                <v-select
                                    :options="options"
                                    v-model="country"
                                ></v-select>
                                <div
                                    v-if="country.value"
                                    @click="clear"
                                    class="clear"
                                >
                                    <svg
                                        fill="#009b74"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 409.81 409.81"
                                    >
                                        <use
                                            xlink:href="#menu-close-icon"
                                        ></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
export default {
    props: {
        countryOption: Array,
    },
    data() {
        return {
            options: [
                {
                    text: this.$t('The Region'),
                    value: '',
                    disabled: true,
                },
            ],
            search: '',
            country: {
                text: this.$t('The Region'),
                value: '',
            },
        }
    },
    methods: {
        changeSearch() {
            this.$emit('search', this.search)
        },
        chageCountry() {
            this.$emit('country', this.country.value)
        },
        clear() {
            this.country = {
                text: this.$t('The Region'),
                value: '',
            }
            this.$emit('country', '')
        },
    },
    watch: {
        countryOption() {
            let allOptions = _.concat(this.options, this.countryOption)
            this.options = cloneDeep(allOptions)
        },
        country: function() {
            this.chageCountry()
        },
    },
}
</script>

<style lang="scss" scoped>
.filters {
    > div {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row {
        > div {
            padding: 0;
        }
    }
    .clear {
        svg {
            fill: #d31c88;
        }
    }
    &--keyword {
        .form-control {
            padding-left: rem(25px);
            color: #009b74;
            background: #fff !important;
            height: 55px;
            line-height: 55px;
            -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
        }
    }
    &--by {
        /deep/ {
            .v-select {
                .arrow-down {
                    margin: 0;
                }
                .v-select-toggle {
                    padding: rem(13px) rem(20px) !important;
                    color: var(--primary) !important;
                    font-size: rem(18px) !important;
                    font-weight: 600 !important;
                    height: 55px !important;
                    line-height: 36px;
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    .arrow-down {
                        zoom: 0.6;
                    }
                    &:focus,
                    &:active,
                    &.active {
                        color: #fff !important;
                    }
                }
            }
        }

        label {
            color: var(--white);
            font-size: rem(22px);
            font-weight: 700;
            margin: 0;
        }
    }
    .clear {
        width: 13px;
        position: absolute;
        top: 24px;
        right: 60px;
        cursor: pointer;
    }
    @media screen and (max-width: 991px) {
        &--by {
            /deep/ {
                .v-select {
                    .v-select-toggle {
                        height: 45px !important;
                    }
                }
            }
        }
        &--keyword {
            .form-control {
                height: 45px;
                line-height: 45px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .row {
            > div {
                padding: 0 15px;
            }
        }
        &--by {
            margin-top: rem(20px);
            .row {
                justify-content: center !important;
            }
        }
    }
    @media screen and (max-width: 575px) {
        &--by {
            .control-label {
                margin-bottom: rem(15px);
            }
        }
    }
}

// ar style

.ar {
    .filters {
        &--keyword {
            .form-control {
                padding-right: rem(25px);
                padding-left: 0.75rem;
            }
        }
        .clear {
            left: 60px;
            right: auto;
        }
    }
}
</style>
