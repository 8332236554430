var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user",attrs:{"data-aos":"fade-down"}},[_c('div',{staticClass:"user__info"},[_c('div',{staticClass:"user__info--image"},[(_vm.user.photo)?_c('img',{attrs:{"loading":"lazy","src":_vm.user.photo,"alt":""}}):_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/person.svg"),"alt":""}})]),_c('div',{staticClass:"user__info--bio"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.user.display_name))]),_c('p',{staticClass:"designation"},[_vm._v(_vm._s(_vm.user.designation))]),_c('p',{staticClass:"country"},[_vm._v(_vm._s(_vm.user.country))])])]),_c('div',{staticClass:"user__links"},[_c('router-link',{staticClass:"user__links--message",attrs:{"to":{
                name: 'messages',
                params: {
                    username: _vm.user.username,
                },
            }}},[_c('svg',{attrs:{"version":"1.1","fill":"currentColor","x":"0px","y":"0px","viewBox":"0 0 512 512","xml:space":"preserve"}},[_c('use',{attrs:{"xlink:href":"#card-message-icon"}})]),_vm._v(" "+_vm._s(_vm.$t('Message'))+" ")]),_c('router-link',{staticClass:"user__links--profile",attrs:{"to":{
                name: 'view-zainers',
                params: {
                    username: _vm.user.username,
                },
            }}},[_c('svg',{attrs:{"version":"1.1","fill":"currentColor","x":"0px","y":"0px","viewBox":"0 0 384 336","xml:space":"preserve"}},[_c('use',{attrs:{"xlink:href":"#card-profile-icon"}})]),_vm._v(" "+_vm._s(_vm.$t('Profile'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }