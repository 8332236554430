<template>
    <div class="user" data-aos="fade-down">
        <div class="user__info">
            <div class="user__info--image">
                <img
                    v-if="user.photo"
                    loading="lazy"
                    :src="user.photo"
                    alt=""
                />
                <img
                    v-else
                    loading="lazy"
                    src="@/assets/images/svg/person.svg"
                    alt=""
                />
            </div>
            <div class="user__info--bio">
                <p class="name">{{ user.display_name }}</p>
                <p class="designation">{{ user.designation }}</p>
                <p class="country">{{ user.country }}</p>
            </div>
        </div>
        <div class="user__links">
            <router-link
                :to="{
                    name: 'messages',
                    params: {
                        username: user.username,
                    },
                }"
                class="user__links--message"
            >
                <svg
                    version="1.1"
                    fill="currentColor"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                >
                    <use xlink:href="#card-message-icon"></use>
                </svg>
                {{ $t('Message') }}
            </router-link>
            <router-link
                :to="{
                    name: 'view-zainers',
                    params: {
                        username: user.username,
                    },
                }"
                class="user__links--profile"
            >
                <svg
                    version="1.1"
                    fill="currentColor"
                    x="0px"
                    y="0px"
                    viewBox="0 0 384 336"
                    xml:space="preserve"
                >
                    <use xlink:href="#card-profile-icon"></use>
                </svg>
                {{ $t('Profile') }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: Object,
    },
}
</script>
<style lang="scss" scoped>
.user {
    max-width: rem(250px);
    text-align: center;
    box-shadow: -2px 0px 12px rgb(197, 197, 197);
    &__info {
        padding: rem(25px) rem(15px);
        &--image {
            img {
                color: grey;
                width: 101px;
            }
        }
        &--bio {
            text-align: center;
            margin-top: rem(20px);
            p {
                margin: 0;
            }
            text-transform: uppercase;
            .name {
                font-size: rem(21px);
                font-weight: 700;
                color: var(--secondary);
                background: linear-gradient(to right, #061c72 0%, #009b74 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .designation {
                font-size: rem(15px);
                letter-spacing: 2px;
                font-weight: 700;
                color: #3f4f9c;
                margin-top: rem(12px);
            }
            .country {
                font-size: rem(14px);
                color: #919091;
                text-transform: none;
                margin: 0;
                margin: rem(20px) 0 0 0;
            }
        }
    }
    &__links {
        display: flex;
        background: rgb(6, 28, 114);
        background: linear-gradient(
            97deg,
            rgba(6, 28, 114, 1) 20%,
            rgba(150, 22, 158, 1) 75%
        );
        font-size: rem(14px);
        font-weight: 700;
        margin-top: auto;
        a {
            color: #fff;
            padding: rem(13px) rem(13px);
            max-width: 50%;
            flex: 0 0 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            svg {
                width: rem(25px);
                min-width: rem(25px);
                height: auto;
                margin-right: 14px;
                @media screen and (max-width: 1199px) and (min-width: 992px) {
                    margin-right: 8px;
                    width: rem(20px);
                    min-width: rem(20px);
                }
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    text-decoration: none;
                    color: var(--pink);
                }
            }
        }
        &--message {
            border-right: 1px solid #fff;
        }
    }
}

// ar style
.ar {
    .user {
        &__links {
            a {
                svg {
                    margin-left: 14px;
                    margin-right: 0;
                    @media screen and (max-width: 1199px) and (min-width: 992px) {
                        margin-left: 8px;
                        margin-right: 0;
                    }
                }
            }
            &--message {
                border-left: 1px solid #fff;
                margin-right: 0;
            }
        }
    }
}
</style>
