<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="zainers">
                                <!-- Filters -->
                                <filters
                                    @search="search"
                                    @country="filterCountry"
                                    :countryOption="countryOption"
                                ></filters>
                                <!-- Filters -->
                                <div class="zainers__profileWrapper">
                                    <!--Profile-->
                                    <zainers-profile-card
                                        v-for="(user, key) in zainersList"
                                        :key="key"
                                        :user="user"
                                    ></zainers-profile-card>
                                    <!--Profile-->
                                </div>
                                <base-loader v-if="zainersLoadding" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/zainers.jpg'
import imgLarge from '@/assets/images/banners/zainers.jpg'
import imgBig from '@/assets/images/banners/zainers.jpg'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import Filters from '@/modules/users/components/zainers/Filters.vue'
import ZainersProfileCard from '@/modules/users/components/zainers/ZainersProfileCard.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
export default {
    components: {
        Filters,
        ZainersProfileCard,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            countryOption: [],
            zainersList: [],
            filters: {
                limit: 6,
                offset: 0,
            },
            zainersLoadding: true,
        }
    },
    async created() {
        SettingsHelper.getCountry()
        this.getZainers()
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        changeFitler: debounce(function(val, event) {
            if (event) event.preventDefault()
            this.getZainers()
        }, 300),
        search(search) {
            this.zainersList = []
            this.filters.search = search
            this.filters.filters = 6
            this.filters.offset = 0
            this.changeFitler()
        },
        filterCountry(country) {
            this.zainersList = []
            this.filters.country = country
            this.filters.filters = 6
            this.filters.offset = 0
            this.changeFitler()
        },
        getZainers() {
            this.zainersLoadding = true
            UsersHelper.getZainers(this.filters)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.zainersLoadding) {
                    let next = this.getNextOffset(this.zainers.next)
                    if (next) {
                        this.zainersLoadding = true
                        this.filters.offset = next
                        this.getZainers()
                    }
                }
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['zainers', 'country', 'dashboardSettings']),
    },
    watch: {
        zainers() {
            if (this.filters.offset == 0) {
                this.zainersList = []
            }
            let allZainers = _.concat(this.zainersList, this.zainers.results)
            this.zainersList = cloneDeep(allZainers)
            this.zainersLoadding = false
        },
        country() {
            this.country.results.forEach(country => {
                this.countryOption.push({
                    value: country.country,
                    text: country.country,
                })
            })
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
.sidenav-wrapper {
    margin-top: 0;
}
.page-contents {
    padding-top: rem(110px);
}
.zainers {
    padding-left: rem(40px);
    padding-top: 0;
    /deep/ {
        .filters {
            &--keyword {
                .form-control {
                    background: var(--white) !important;
                }
            }
        }
    }
    &__profileWrapper {
        margin-top: rem(75px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
            max-width: 31%;
            flex: 0 0 31%;
            margin-bottom: rem(40px);
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (max-width: 991px) {
        padding-left: 0;
    }
    @media screen and (max-width: 767px) {
        &__profileWrapper {
            > div {
                max-width: 47%;
                flex: 0 0 47%;
            }
        }
    }
    @media screen and (max-width: 575px) {
        &__profileWrapper {
            > div {
                max-width: 95%;
                flex: 0 0 95%;
                margin: auto auto rem(30px) auto;
            }
        }
    }
}
/deep/ {
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
    .user {
        &__info {
            &--bio {
                .designation {
                    margin-top: 5px;
                }
                .name {
                    background: linear-gradient(
                        179deg,
                        #009b74 0%,
                        var(--green) 100%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .content-wrapper {
            .page__head {
                min-height: 125px;
            }
        }
        .page-banner {
            --h: 630px;
        }
    }
    @media screen and (max-width: 991px) {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    padding-left: 5rem !important;
                }
            }
        }
        .page-banner {
            --h: 565px;
        }
    }
    @media screen and (max-width: 575px) {
        .content-wrapper {
            .page__head {
                min-height: 130px;
            }
        }
        .page-banner {
            --h: 515px;
        }
        .page-contents {
            padding-top: rem(20px);
        }
    }
}

// ar style

.ar {
    .zainers {
        padding-right: rem(40px);
        padding-left: 0;
        @media screen and (max-width: 991px) {
            padding-right: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    padding-right: 5rem !important;
                    padding-left: 0 !important;
                }
            }
        }
    }
}
</style>
